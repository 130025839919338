import React from "react";
import Container from "../Container/Container";
import SectionTitle from "./SectionTitle/SectionTitle";
import Button from "../Button/Button";
import { RootProps } from "../../@types/PageContext";

const CTAMain: React.FC<RootProps> = props => {
    const { pageContext } = props;
    const { title, subtitle, buttonName } = pageContext.content.cta;
    const { presentationURL } = pageContext.content.file;

    return (
        <Container maxWidth="xl" className="cta-main">
            <div className="display--flex flex--centered flex--column">
                <SectionTitle title={title} subtitle={subtitle} />
                <Button size="lg" color="brand-1" link={presentationURL}>{buttonName}</Button>
            </div>
        </Container>
    )
}

export default CTAMain;
