import React from "react";
import Typography from "../../Typography/Typography";
import uuid from 'react-uuid';

interface ContentProps {
    paragraphs: string[];
}

const Content: React.FC<ContentProps> = props => {
    return (
        <div className="site-content">
            {props.paragraphs.map(p => <Typography key={uuid()} variant="body1" color="secondary"> {p}</Typography>)}
        </div>
    );
}

export default Content;