import React from "react";
import { navigate } from 'gatsby';
import SITE_CONTENT from '../../content/landing.json';
import { PageContext } from "../@types/PageContext";

const useLocaleRedirect = (path: string, pageContext: PageContext) => {

    React.useEffect(() => {
        if (typeof window === 'undefined') return;

        const storage = window.localStorage;
        const languageStorage = storage.getItem('language');
        const userLanguage = window.navigator.language;

        // language is set
        if (languageStorage) {
            if (languageStorage !== pageContext.lang && path !== pageContext.slug) {
                console.log("redirecting", SITE_CONTENT[languageStorage].slug);
                navigate(SITE_CONTENT[languageStorage].slug);
            }
            return;
        }

        // unknown language
        if (!SITE_CONTENT[userLanguage]) {
            navigate('/');
            storage.setItem('language', 'en');
            return;
        }

        if (path !== `/${SITE_CONTENT[userLanguage].slug}`) {
            navigate(`/${SITE_CONTENT[userLanguage].slug}`);
            storage.setItem('language', userLanguage);
            return;
        }

    }, []);
};

export default useLocaleRedirect;