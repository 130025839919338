import React from "react"
import AboutUs from "../components/LANDING/AboutUs";
import CTAMain from "../components/LANDING/CTAMain";
import MeasuredResults from "../components/LANDING/MeasuredResults";
import WhatIsIt from "../components/LANDING/WhatIsIt";
import WhyUse from "../components/LANDING/WhyUse";
import useLocaleRedirect from "../hooks/useLocaleRedirect";

export default function Homepage({ path, data, pageContext }) {
  useLocaleRedirect(path, pageContext);

  return (
    <>
      <WhatIsIt pageContext={pageContext} />
      <WhyUse pageContext={pageContext} />
      <MeasuredResults pageContext={pageContext} />
      <CTAMain pageContext={pageContext} />
      <AboutUs pageContext={pageContext} />
    </>
  )
}
