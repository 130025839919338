import React from "react";
import Container from "../Container/Container";
import SectionTitle from "./SectionTitle/SectionTitle";
import SITE_CONTENT from "../../intl/siteContent";
import uuid from 'react-uuid';
import Icon, { IconInterface, ICONS } from "../Icon/Icon";
import Typography from "../Typography/Typography";
import { PageContentIcon, PageContentWhyUse, RootProps } from "../../@types/PageContext";

const WhyUse: React.FC<RootProps> = props => {
    const { pageContext } = props;
    const { title, subtitle, icons } = pageContext.content.whyUse;

    return (
        <Container maxWidth="xl" className="why-use" wrapperClassName="section-padding">
            <div className="display--flex flex--centered flex--column">
                <SectionTitle light title={title} subtitle={subtitle} centered />
                <WhyUseIcons icons={icons} />
            </div>
        </Container>
    )
}

export default WhyUse;

interface WhyUseProps {
    icons: PageContentIcon[];
}

const WhyUseIcons: React.FC<WhyUseProps> = props => {
    const { icons } = props;

    const ICON_PICKER = {
        "quality": ICONS.QUALITY,
        "simple-implementation": ICONS.SIMPLE_IMPLEMENTATION,
        "natural-100": ICONS.NATURAL_100,
        "tasteless-odorless-colorless": ICONS.TASTELESS_ODORLESS_COLORLESS,
        "science": ICONS.SCIENCE
    };

    return (
        <div className="icons-why-use">
            {icons.map(item => <WhyUseIcon key={uuid()} iconName={ICON_PICKER[item.icon]} title={item.title} />)}
        </div>
    )
}

interface WhyUseIconProps extends IconInterface {
    title: string;
}

const WhyUseIcon: React.FC<WhyUseIconProps> = props => {
    return (
        <div className="icon-why-use">
            <div className="icon-why-use--icon">
                <Icon {...props} />
            </div>
            <Typography variant="subtitle2" color="primary" light>{props.title}</Typography>
        </div>
    );
}