import React from "react";
import Container from "../Container/Container";
import SectionTitle from "./SectionTitle/SectionTitle";
import Image from '../../../static/measured-results.jpg';
import Typography from "../Typography/Typography";
import uuid from 'react-uuid';
import Icon, { ICONS } from "../Icon/Icon";
import { QuoteInterface, RootProps } from "../../@types/PageContext";

const MeasuredResults: React.FC<RootProps> = props => {
    const { pageContext } = props;
    const { title, subtitle, quotes } = pageContext.content.measuredResults;

    return (
        <Container maxWidth="xl" wrapperClassName="section-padding">
            <SectionTitle title={title} subtitle={subtitle} />
            <Quotes quotes={quotes} />
            <img src={Image} alt="image" className="img-fluid img-cropped-600" />
        </Container>
    )
}

export default MeasuredResults;

interface QuotesProps {
    quotes: QuoteInterface[];
}

const Quotes: React.FC<QuotesProps> = props => {
    const { quotes } = props;
    return (
        <div className="quotes">
            {quotes.map(item => <Quote {...item} key={uuid()} />)}
        </div>
    );
}

const Quote: React.FC<QuoteInterface> = props => {
    const { quote, author } = props;
    return (
        <div className="display--flex quote">
            <Icon iconName={ICONS.QUOTE} color="#1C9D75" />
            <div>
                <Typography variant="body2" color="primary">{quote}</Typography>
                <Typography variant="caption" color="primary" className="text--italic">{author}</Typography>
            </div>
        </div>
    );
}