import React from "react";
import Container from "../Container/Container";
import SectionTitle from "./SectionTitle/SectionTitle";
import Image from '../../../static/about.jpg';
import Content from "./Content/Content";
import { RootProps } from "../../@types/PageContext";

const AboutUs: React.FC<RootProps> = props => {
    const { pageContext } = props;
    const { title, subtitle, paragraphs } = pageContext.content.aboutUs;

    return (
        <Container maxWidth="xl" wrapperClassName="grid-content section-padding">
            <div>
                <SectionTitle title={title} subtitle={subtitle} />
                <Content paragraphs={paragraphs} />
            </div>
            <img src={Image} alt="image" className="img-fluid" />
        </Container>
    )
}

export default AboutUs;